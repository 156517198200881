@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&family=Poppins:wght@600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9A4kDNxMZdWfMOD5VvkrCqUT3fcWTP.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9A4kDNxMZdWfMOD5VvkrCqUTTfcWTP.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9A4kDNxMZdWfMOD5VvkrCqUTzfcWTP.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9A4kDNxMZdWfMOD5VvkrCqUTPfcWTP.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9A4kDNxMZdWfMOD5VvkrCqUT_fcWTP.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9A4kDNxMZdWfMOD5VvkrCqUT7fcWTP.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9A4kDNxMZdWfMOD5VvkrCqUTDfcQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrAGQCf1VFn2lg.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrAGQCf8VFn2lg.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrAGQCf0VFn2lg.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrAGQCf7VFn2lg.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrAGQCf3VFn2lg.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrAGQCf2VFn2lg.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrAGQCf4VFk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBiQyf1VFn2lg.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBiQyf8VFn2lg.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBiQyf0VFn2lg.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBiQyf7VFn2lg.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBiQyf3VFn2lg.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBiQyf2VFn2lg.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBiQyf4VFk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9C4kDNxMZdWfMOD5VvkrjEYTLHdQ.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9C4kDNxMZdWfMOD5VvkrjNYTLHdQ.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9C4kDNxMZdWfMOD5VvkrjFYTLHdQ.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9C4kDNxMZdWfMOD5VvkrjKYTLHdQ.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9C4kDNxMZdWfMOD5VvkrjGYTLHdQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9C4kDNxMZdWfMOD5VvkrjHYTLHdQ.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9C4kDNxMZdWfMOD5VvkrjJYTI.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrA6Qif1VFn2lg.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrA6Qif8VFn2lg.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrA6Qif0VFn2lg.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrA6Qif7VFn2lg.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrA6Qif3VFn2lg.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrA6Qif2VFn2lg.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrA6Qif4VFk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrAWRSf1VFn2lg.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrAWRSf8VFn2lg.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrAWRSf0VFn2lg.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrAWRSf7VFn2lg.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrAWRSf3VFn2lg.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrAWRSf2VFn2lg.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrAWRSf4VFk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrByRCf1VFn2lg.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrByRCf8VFn2lg.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrByRCf0VFn2lg.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrByRCf7VFn2lg.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrByRCf3VFn2lg.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrByRCf2VFn2lg.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrByRCf4VFk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBuRyf1VFn2lg.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBuRyf8VFn2lg.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBuRyf0VFn2lg.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBuRyf7VFn2lg.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBuRyf3VFn2lg.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBuRyf2VFn2lg.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBuRyf4VFk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9C4kDNxMZdWfMOD5Vn9LjEYTLHdQ.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9C4kDNxMZdWfMOD5Vn9LjNYTLHdQ.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9C4kDNxMZdWfMOD5Vn9LjFYTLHdQ.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9C4kDNxMZdWfMOD5Vn9LjKYTLHdQ.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9C4kDNxMZdWfMOD5Vn9LjGYTLHdQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9C4kDNxMZdWfMOD5Vn9LjHYTLHdQ.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9C4kDNxMZdWfMOD5Vn9LjJYTI.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnWKneSxf6TF0.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnWKneQhf6TF0.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnWKneShf6TF0.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnWKneRRf6TF0.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnWKneSRf6TF0.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnWKneSBf6TF0.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnWKneRhf6.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnPKreSxf6TF0.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnPKreQhf6TF0.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnPKreShf6TF0.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnPKreRRf6TF0.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnPKreSRf6TF0.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnPKreSBf6TF0.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnPKreRhf6.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9E4kDNxMZdWfMOD5VvmojLeTY.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9E4kDNxMZdWfMOD5Vvk4jLeTY.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9E4kDNxMZdWfMOD5Vvm4jLeTY.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9E4kDNxMZdWfMOD5VvlIjLeTY.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9E4kDNxMZdWfMOD5VvmIjLeTY.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9E4kDNxMZdWfMOD5VvmYjLeTY.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9E4kDNxMZdWfMOD5Vvl4jL.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnZKveSxf6TF0.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnZKveQhf6TF0.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnZKveShf6TF0.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnZKveRRf6TF0.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnZKveSRf6TF0.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnZKveSBf6TF0.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnZKveRhf6.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnSKzeSxf6TF0.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnSKzeQhf6TF0.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnSKzeShf6TF0.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnSKzeRRf6TF0.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnSKzeSRf6TF0.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnSKzeSBf6TF0.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnSKzeRhf6.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnLK3eSxf6TF0.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnLK3eQhf6TF0.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnLK3eShf6TF0.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnLK3eRRf6TF0.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnLK3eSRf6TF0.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnLK3eSBf6TF0.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnLK3eRhf6.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnMK7eSxf6TF0.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnMK7eQhf6TF0.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnMK7eShf6TF0.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnMK7eRRf6TF0.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnMK7eSRf6TF0.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnMK7eSBf6TF0.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnMK7eRhf6.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnFK_eSxf6TF0.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnFK_eQhf6TF0.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnFK_eShf6TF0.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnFK_eRRf6TF0.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnFK_eSRf6TF0.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnFK_eSBf6TF0.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnFK_eRhf6.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body{
  font-family: 'Fira Sans', sans-serif;

}


.stepper .StepButton-0-2-4 {
    width: 3em ;
    border: none ;
    cursor: pointer ;
    height: 3em ;
    margin: 0px ;
    display: flex ;
    padding: 0px ;
    font-size: 1em ;
    align-items: center ;
    border-radius: 50% ;
    justify-content: center ;
    background-color: rgb(255, 255, 255) ;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  width: 100%;
  margin: 0 auto;
  background-color: #e9f8fe71;
  min-height: 100vh;

  }
  
  .main-container__header, .main-container__subheader {
    text-align: center;
  }
  
  .form {
    width: 1400px;
    margin: 0 auto;
    /* background-color: #fafafa; */
    /* padding: 25px 15px; */
  }
  
  .form-group {
    text-align: center
  }
  
  .form-group__element {
    margin: 10px auto;
    grid-gap: 20px;
    gap: 20px;
    max-width: 400px;
   
  }
  
  .form-group__label {
    display: block;
    width: 100%;
    margin: 0 auto;
    text-align: start;
    font-size: 16px;
    font-weight: 400;
    color: #222;
    line-height: 24px;
    margin-bottom: 5px;
  }

  
  
  .form-group__input {
    width: 100%;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #ddd;
  }
  
  .buttons.footer_btn{  
    display: flex;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    max-width: 400px;
    margin: 0px auto;
    padding: 20px;
}
  
  .buttons__button{
    padding: 10px 30px;
    border-radius: 3px;
    border: none;
    margin-top: 20px;
    color: white;
    background: #084e93;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
   

}

button.buttons__button.buttons__button--back {
  background: #757575b3;
  font-size: 14px;
  line-height: 22px;
}

  
  .buttons__button:hover {
    cursor: pointer;
    box-shadow: 3px 2px 3px rgba(175, 175, 175, 0.4);
  }
  
 
  

  
  .select {
    text-align: center;
  }
  
  .select__item {
    width: 500px;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #003487;
  }
  
  .select__item:hover {
    cursor: pointer
  }
  
  .table {
    width: 500px;
    margin: 15px auto;
  }
  
  .summary {
    width: 80%;
    margin: 20px auto;
    border: 1px solid #084e93;
    padding: 10px;
    border-radius: 3px;
  }
  
  .summary__heading {
    margin-top: 5px;
    text-align: center;
  }
  
  .summary__item-title {
    font-weight: bold;
  }
  
  .stepper {
    padding: 10px 0 !important;
  }
  
  .stepper__step {
    border: 1px solid #084e93 !important;
    cursor: default !important;
  }
  
  .stepper__step + div > span {
    color: #084e93 !important;
  }
  
  .RFS-ConnectorContainer {
    left: calc((-50% + 2em) - 8px) !important;
    right: calc((50% + 2em) - 8px) !important;
  }
  
  .error {
    margin-top: 0;
    color: #c51e1e !important;
    font-size: 90%;
  }
/* 
  .Connector-d31-0-2-117 , .Connector-d47-0-2-173 ,.Connector-d63-0-2-229 , .Connector-d79-0-2-285 , .Connector-d95-0-2-341{
    display: block !important;
    border-color: rgb(189, 189, 189)!important;
    border-top-style: solid !important;
    border-top-width: 1px !important;
}

.Connector-d31-0-2-117 , .Connector-d47-0-2-173 , .Connector-d63-0-2-229 , .Connector-d79-0-2-285 , .Connector-d95-0-2-341 {
    position: absolute !important;
    right: 0 !important;
    top: 22px !important;
    left: -25px !important;
    width: 57px !important;
}
.StepButton-d98-0-2-280 .active {
    background-color: rgb(43, 124, 255);
} */





* {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --hue: 182;
  --bg: hsl(var(--hue), 10%, 90%);
  --fg: hsl(var(--hue), 10%, 10%);
  --primary-color: hsl(var(--hue), 92%, 31%);
  --trans-dur: 0.3s;
  font-size: calc(16px + 4 * (100vw - 320px) / 960);
}

.popup-overlay {
  position: fixed;
  inset: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
}

.loader-container {
  background: hsla(182, 10%, 90%, 0.1);
  background: hsla(var(--hue), 10%, 90%, 0.1);
  border-radius: 10px;
  overflow: hidden;
  height: 17px;
  font-size: 12px;
}
.loader-container .progress-bar {
  background-color: #ff9d00;
}

.popup-body {
  padding: 0px !important;
  border-radius: 5px;
  width: 40%;
  background-color: hsl(182, 10%, 90%);
  background-color: var(--bg);
  color: hsl(182, 10%, 10%);
  color: var(--fg);
  min-height: 650px;
  display: grid;
  place-items: center;
  transition: background-color 0.3s, color 0.3s;
  transition: background-color var(--trans-dur), color var(--trans-dur);
}

.footer-text {
  margin-top: 1rem;
  color: red;
  font-weight: 500;
  font-size: 18px;
}

.preloader {
  text-align: center;
  max-width: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}
.preloader .text {
  font-size: 1.2rem;
}

.cart {
  display: block;
  margin: 0 auto 1.5em auto;
  width: 8em;
  height: 8em;
}

.cart__lines,
.cart__top,
.cart__wheel1,
.cart__wheel2,
.cart__wheel-stroke {
  -webkit-animation: cartLines 2s ease-in-out infinite;
          animation: cartLines 2s ease-in-out infinite;
}

.cart__lines {
  stroke: #ff9d00;
}

.cart__top {
  -webkit-animation-name: cartTop;
          animation-name: cartTop;
}

.cart__wheel1 {
  -webkit-animation-name: cartWheel1;
          animation-name: cartWheel1;
  transform: rotate(-0.25turn);
  transform-origin: 43px 111px;
}

.cart__wheel2 {
  -webkit-animation-name: cartWheel2;
          animation-name: cartWheel2;
  transform: rotate(0.25turn);
  transform-origin: 102px 111px;
}

.cart__wheel-stroke {
  -webkit-animation-name: cartWheelStroke;
          animation-name: cartWheelStroke;
}

.cart__track {
  stroke: hsla(182, 10%, 10%, 0.1);
  stroke: hsla(var(--hue), 10%, 10%, 0.1);
  transition: stroke 0.3s;
  transition: stroke var(--trans-dur);
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 0%, 10%);
    --fg: hsl(var(--hue), 0%, 90%);
  }
  .cart__track {
    stroke: hsla(182, 10%, 90%, 0.1);
    stroke: hsla(var(--hue), 10%, 90%, 0.1);
  }
}
@-webkit-keyframes cartLines {
  from, to {
    opacity: 0;
  }
  8%, 92% {
    opacity: 1;
  }
}
@keyframes cartLines {
  from, to {
    opacity: 0;
  }
  8%, 92% {
    opacity: 1;
  }
}
@-webkit-keyframes cartTop {
  from {
    stroke-dashoffset: -338;
  }
  50% {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 338;
  }
}
@keyframes cartTop {
  from {
    stroke-dashoffset: -338;
  }
  50% {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 338;
  }
}
@-webkit-keyframes cartWheel1 {
  from {
    transform: rotate(-0.25turn);
  }
  to {
    transform: rotate(2.75turn);
  }
}
@keyframes cartWheel1 {
  from {
    transform: rotate(-0.25turn);
  }
  to {
    transform: rotate(2.75turn);
  }
}
@-webkit-keyframes cartWheel2 {
  from {
    transform: rotate(0.25turn);
  }
  to {
    transform: rotate(3.25turn);
  }
}
@keyframes cartWheel2 {
  from {
    transform: rotate(0.25turn);
  }
  to {
    transform: rotate(3.25turn);
  }
}
@-webkit-keyframes cartWheelStroke {
  from, to {
    stroke-dashoffset: 81.68;
  }
  50% {
    stroke-dashoffset: 40.84;
  }
}
@keyframes cartWheelStroke {
  from, to {
    stroke-dashoffset: 81.68;
  }
  50% {
    stroke-dashoffset: 40.84;
  }
}
@media (max-width: 1000px) {
  .popup-body {
    padding: 0 20px !important;
    width: 80%;
  }
  .preloader {
    max-width: 100%;
  }
}/*# sourceMappingURL=loader.css.map */
:root {
  font-size: 16px;
  --heading-color: #001020;
  --pure-white: #FFF;
  --border-color: #069399;
  --dark-bg: #001020;
  --btn-hover: #005694;
  --prominent-color: rgb(119, 0, 0);
  --new-main-color: #ffc260;
  --new-secondary-color: #f1ece6;
  --dark-red: #E91A1B;
}

.mt--1 {
  margin-top: -1rem !important;
}

.highlightOption, .multiSelectContainer li:hover {
  background-color: #ffc260;
  background-color: var(--new-main-color);
  color: #000;
}

.so-relative {
  position: relative;
}

.no-scroll {
  overflow: hidden;
}

.min-h-100 {
  min-height: 100vh;
}

@font-face {
  font-family: "Brandon Grotesque";
  font-weight: light;
  src: url(/static/media/brandon-grotesque-light-58a8a4b38001d.55d44807.otf) format("opentype");
}
@font-face {
  font-family: "Brandon Grotesque";
  font-weight: normal;
  src: url(/static/media/HvDTrial_Brandon_Grotesque_regular-BF64a625c9311e1.1381af6d.otf) format("opentype");
}
@font-face {
  font-family: "Brandon Grotesque";
  font-weight: bold;
  src: url(/static/media/HvDTrial_Brandon_Grotesque_bold-BF64a625c9151d5.bc46786f.otf) format("opentype");
}
.dark-red {
  color: #E91A1B !important;
  color: var(--dark-red) !important;
}
.dark-red.font-bold {
  font-weight: bold;
}

.center {
  display: flex;
  flex-direction: column;
  min-height: 60vh;
  justify-content: center;
  align-items: center;
}

.btn-unsubscribe {
  background-color: #E91A1B;
  background-color: var(--dark-red);
  padding: 20px 50px;
  margin-top: 3rem;
  color: #fff;
  font-size: 1.35rem;
  border-radius: 100px;
  transition: all 0.3s ease-in-out;
}
.btn-unsubscribe:hover {
  transform: scale(1.1);
}

.new-banner {
  border: 4px solid #ffc260;
  border: 4px solid var(--new-main-color);
  padding: 2rem 0 2rem 0;
  background-color: #f1ece6;
  background-color: var(--new-secondary-color);
  font-family: "Poppins", sans-serif;
}
.new-banner .logo-section img {
  width: 110px;
}
.new-banner .main-heading {
  text-align: center;
  font-weight: bold;
  font-size: 45px;
  margin-top: 2rem;
}
.new-banner .main-heading .colored {
  background-color: #ffc260;
  background-color: var(--new-main-color);
}
.new-banner .notice-text {
  text-align: center;
  margin: 2rem auto;
}
.new-banner .notice-text * {
  margin: 0;
}
.new-banner .notice-text h2 {
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}
.new-banner .notice-text h5 {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.75em;
  color: #001020;
  color: var(--heading-color);
}
.new-banner .mail-box {
  width: 60%;
  margin: 0 auto;
  position: relative;
}
.new-banner .mail-box input {
  background-color: transparent;
  border: 2px solid #ffc260;
  border: 2px solid var(--new-main-color);
  width: 100%;
  padding: 1rem 12rem 1rem 1rem;
  border-radius: 50px;
}
.new-banner .mail-box input:focus-visible {
  outline: unset;
  box-shadow: unset;
}
.new-banner .mail-box button {
  background-color: #ffc260;
  background-color: var(--new-main-color);
  position: absolute;
  width: 150px;
  height: 42px;
  border-radius: 50px;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-weight: bold;
}
.new-banner .mail-box button:focus-within {
  outline: unset;
  box-shadow: unset !important;
}
.new-banner .note {
  margin-top: 0.5rem;
  text-align: center;
  color: red;
  font-size: 14px;
}
.new-banner .video-section .video-react-poster {
  background-size: cover;
}
.new-banner .sub-tagline {
  text-align: center;
  font-size: 20px;
  margin-top: 2rem;
}
.new-banner .so-btn {
  border-radius: 0px;
  background: #ffc260;
  background: var(--new-main-color);
  padding: 1.25rem 5rem;
  font-size: 1.675rem;
  font-weight: bold;
  font-size: 18px;
  transition: all 0.2s;
  margin-bottom: 2rem;
  border: 2px solid transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.new-banner .so-btn.is-affiliate {
  margin-bottom: 35rem;
}
.new-banner .so-btn:hover {
  color: #e7b86c;
  background-color: #000;
  border-width: 2px;
  border-color: #e7b86c;
}
.new-banner .partners-img {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
}
.new-banner .partners-img img:first-child {
  width: 240px;
}
.new-banner .partners-img img:last-child {
  width: 80px;
}
@media (max-width: 600px) {
  .new-banner .logo-section img {
    width: 80px;
  }
  .new-banner .main-heading {
    font-size: 25px;
  }
  .new-banner .mail-box {
    width: 100%;
  }
  .new-banner .mail-box button {
    padding: 0;
  }
  .new-banner .notice-text h2 {
    font-size: 22px;
  }
  .new-banner .notice-text h5 {
    font-size: 16px;
  }
  .new-banner .sub-tagline {
    font-size: 16px;
    margin: 1rem auto;
  }
  .new-banner .btn {
    padding: 17px;
    width: 100%;
    font-size: 16px;
  }
  .new-banner .partners-img img:first-child {
    width: 180px;
  }
  .new-banner .partners-img img:last-child {
    width: 55px;
  }
}

.so-main-container {
  font-family: "Brandon Grotesque";
  font-weight: light;
}
.so-main-container .banner {
  padding-top: 50px;
  padding-bottom: 50px;
  background-image: url(/static/media/home-banner-bg.e34acae6.jpg);
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  position: relative;
  color: #FFF;
  color: var(--pure-white);
}
.so-main-container .banner.is-affiliate {
  margin-bottom: 5rem;
}
.so-main-container .banner h1 {
  font-size: 3.5rem;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 1.5rem;
}
.so-main-container .banner p {
  font-size: 2rem;
  font-style: normal;
  line-height: normal;
  margin-bottom: 1.5rem;
}
.so-main-container .banner small {
  display: block;
  margin-bottom: 2rem;
  font-size: 1.75rem;
  font-style: normal;
  line-height: normal;
}
.so-main-container .banner .btn {
  border-radius: 8px;
  background: #001020;
  background: var(--dark-bg);
  padding: 2rem 5rem;
  box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.2);
  font-size: 1.675rem;
  color: #FFF;
  color: var(--pure-white);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  transition: all 0.3s ease-in-out;
  margin-bottom: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.so-main-container .banner .btn.is-affiliate {
  margin-bottom: 35rem;
}
.so-main-container .banner .btn:hover {
  transform: scale(1.05);
  background-color: #005694;
  background-color: var(--btn-hover);
}
.so-main-container .banner .divider {
  position: absolute;
  left: 0rem;
  bottom: -1rem;
  z-index: 1;
  width: 100%;
}
.so-main-container .banner .divider svg {
  fill: #FFF;
  fill: var(--pure-white);
}
.so-main-container .banner .absolute {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  z-index: 2;
  width: 100%;
}
.so-main-container .banner .logo-section {
  width: 120px;
}
.so-main-container .banner .logo-section.affiliate-logo {
  margin: 0 auto 120px;
}
.so-main-container .banner .text {
  font-size: 2.25rem;
  font-weight: bold;
}
.so-main-container .banner .text-colored {
  color: rgb(119, 0, 0);
  color: var(--prominent-color);
}
.so-main-container .banner .video-section {
  margin: 50px auto 50px;
  border-radius: 8px;
  overflow: hidden;
}
.so-main-container .banner .video-section .video-react-poster {
  background-size: cover;
}
.so-main-container .banner .video-section .important {
  background-color: rgb(119, 0, 0);
  background-color: var(--prominent-color);
  font-size: 1.35rem;
  padding: 1rem 2rem;
}
.so-main-container .working {
  margin-bottom: 100px;
}
.so-main-container .working h2 {
  font-size: 3rem;
  font-weight: bold;
  color: #001020;
  color: var(--heading-color);
  text-align: center;
  margin-bottom: 2rem;
}
.so-main-container .working .card {
  border-radius: 8px;
  border: 1px solid #069399;
  border: 1px solid var(--border-color);
  background: #FFF;
  box-shadow: 0px 15px 30px 0px rgba(37, 229, 195, 0.1);
  padding-top: 20px;
  min-height: 380px;
}
.so-main-container .working .card .icon {
  margin-bottom: 30px;
}
.so-main-container .working .card .title {
  font-size: 1.75rem;
  font-weight: bold;
  color: #001020;
  color: var(--heading-color);
  margin-bottom: 1rem;
}
.so-main-container .working .card p {
  color: #444;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}
.so-main-container .about {
  background: rgba(37, 229, 195, 0.1);
  padding: 90px 0;
}
.so-main-container .about.admin-box {
  background: #f1ece6;
  background: var(--new-secondary-color);
}
.so-main-container .about img {
  width: 486px;
  height: 553px;
  object-fit: cover;
  border-radius: 8px;
}
.so-main-container .about p {
  color: #444;
  text-align: justify;
  font-size: 1.125rem;
  font-style: normal;
  line-height: 155%;
}
.so-main-container .about strong {
  font-weight: bold;
}
.so-main-container footer {
  margin: 60px auto;
  color: #444;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  /* 225% */
}
.so-main-container footer small {
  color: #444;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
}
.so-main-container footer ul {
  list-style: none;
  grid-gap: 2rem;
  gap: 2rem;
}
.so-main-container footer ul li a {
  color: #444;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
}
.so-main-container footer ul li a:hover {
  color: #069399;
  color: var(--border-color);
}
.so-main-container footer ul li:not(:first-child) {
  position: relative;
}
.so-main-container footer ul li:not(:first-child):before {
  content: "|";
  position: absolute;
  left: -20px;
}

.expired-popup {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border: 3px solid #ffc260;
  border: 3px solid var(--new-main-color);
  border-radius: 5px;
}
.expired-popup img {
  width: 140px;
}
.expired-popup .btn-continue {
  border-radius: 8px;
  background: linear-gradient(90deg, #ffc260 0%, #fd9c00 125.83%);
  box-shadow: 0px 7.5px 20px 0px rgba(0, 0, 0, 0.1);
  color: #FFF;
  color: var(--pure-white);
  padding: 0.75rem 1.25rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  margin-top: 2rem;
  min-width: 200px;
  transition: all 0.3s ease-in-out;
  box-shadow: unset !important;
}
.expired-popup .btn-continue .spinner-border {
  width: 20px;
  height: 20px;
}
.expired-popup .btn-continue:hover {
  color: #FFF;
  color: var(--pure-white);
  transform: scale(1.1);
}

.disabled {
  opacity: 0.7 !important;
  pointer-events: none !important;
  cursor: not-allowed;
}
.disabled:hover {
  transform: unset !important;
}

.so-form-container {
  min-height: 100vh;
  background-color: #f1ece6;
  background-color: var(--new-secondary-color);
  font-family: "Inter";
}
.so-form-container .visit p {
  margin-top: 15px !important;
  color: #fd9c00;
  font-weight: bold;
}
.so-form-container .visit .so-btn {
  background-color: #069399;
  background-color: var(--border-color);
  color: #FFF;
  color: var(--pure-white);
  margin-top: 1rem;
  transition: all 0.3s ease-in-out;
  padding: 0.5rem 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Brandon Grotesque";
}
.so-form-container .visit .so-btn:hover {
  transform: scale(1.05);
  background-color: #005694;
  background-color: var(--btn-hover);
}
.so-form-container .access-steps {
  margin-bottom: 1.5rem;
}
.so-form-container .access-steps h3 {
  font-family: "Poppins";
  font-size: 1.35rem;
}
.so-form-container .access-steps ul {
  margin-left: 1rem;
}
.so-form-container h3.details {
  font-family: "Poppins";
  font-size: 1.35rem;
}
.so-form-container a.so-access-btn {
  border-radius: 8px;
  background: linear-gradient(90deg, #ffc260 0%, #fd9c00 125.83%);
  box-shadow: 0px 7.5px 20px 0px rgba(0, 0, 0, 0.1);
  color: #FFF;
  color: var(--pure-white);
  padding: 0.75rem 1.25rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  max-width: 200px;
  grid-gap: 20px;
  gap: 20px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.so-form-container a.so-access-btn:hover {
  transform: scale(1.1);
}
.so-form-container a.so-access-btn img {
  width: 25px;
}
.so-form-container .creds {
  border: 2px solid #ffc260;
  border: 2px solid var(--new-main-color);
  padding: 1.5rem;
  border-radius: 12px;
  grid-gap: 2rem;
  gap: 2rem;
}
@media (max-width: 500px) {
  .so-form-container .creds .access-btn {
    width: 100%;
  }
  .so-form-container .creds .access-btn a.so-access-btn {
    max-width: 100%;
  }
}
@media (max-width: 500px) {
  .so-form-container .creds {
    flex-direction: column;
    align-items: unset;
  }
}
.so-form-container .creds .left {
  flex: 1 1;
}
.so-form-container .creds .divider {
  margin: 15px auto;
  border: 0.5px dashed #ffc260;
  border: 0.5px dashed var(--new-main-color);
  width: 100%;
  height: 1px;
}
.so-form-container .creds .item {
  grid-gap: 2rem;
  gap: 2rem;
}
@media (max-width: 600px) {
  .so-form-container .creds .item {
    grid-gap: 10px;
    gap: 10px;
  }
}
.so-form-container .creds .item p:first-child {
  width: 20%;
  text-align: left;
}
@media (max-width: 600px) {
  .so-form-container .creds .item p:first-child {
    width: 100%;
  }
}
.so-form-container .creds .item .d-flex {
  grid-gap: 1rem;
  gap: 1rem;
}
@media (max-width: 600px) {
  .so-form-container .creds .item .d-flex {
    width: 100%;
  }
  .so-form-container .creds .item .d-flex strong {
    text-align: left;
    width: 70%;
    overflow: hidden;
  }
  .so-form-container .creds .item .d-flex .copy {
    width: 30%;
  }
}
.so-form-container .creds .item .copy {
  color: #ffc260;
  color: var(--new-main-color);
  padding-left: 1rem;
  position: relative;
  text-transform: capitalize;
  font-weight: 600;
  cursor: pointer;
}
.so-form-container .creds .item .copy svg {
  margin-right: 5px;
}
.so-form-container .creds .item .copy svg path {
  stroke: #ffc260;
  stroke: var(--new-main-color);
}
.so-form-container .creds .item .copy::before {
  content: "";
  height: 100%;
  width: 1px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #ccc;
}
.so-form-container .head-title {
  margin-bottom: 2rem;
}
.so-form-container .head-title .centr-logo {
  width: 10rem;
  margin: 0 auto 1.75rem;
  padding: 0;
}
.so-form-container .head-title h1 {
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #001020;
  color: var(--heading-color);
  font-family: "Poppins";
}
.so-form-container .head-title p {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #001020;
  color: var(--heading-color);
}
@media (max-width: 600px) {
  .so-form-container .head-title {
    text-align: left !important;
  }
  .so-form-container .head-title .centr-logo {
    width: 80px;
  }
  .so-form-container .head-title h1 {
    font-size: 1.25rem;
  }
}
.so-form-container .thankyou-title .centr-logo {
  width: 10rem;
  margin: 0 auto 1.75rem;
  padding: 0;
}
@media (max-width: 600px) {
  .so-form-container .thankyou-title .centr-logo {
    width: 80px;
  }
}
.so-form-container .card {
  border-radius: 8px;
  border: 3px solid #ffc260;
  border: 3px solid var(--new-main-color);
  background: #FFF;
  background: var(--pure-white);
  padding: 50px 95px 60px;
  margin: 2rem auto;
}
.so-form-container .card form h4 {
  text-align: left !important;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #001020;
  color: var(--heading-color);
  margin-bottom: 2rem;
}
.so-form-container .card form h4 .note {
  font-size: 1rem;
  color: #069399;
  color: var(--border-color);
}
.so-form-container .card form .RFS-StepperContainer {
  border-radius: 8px;
  border: 2px solid #ffc260;
  border: 2px solid var(--new-main-color);
  padding: 1.5rem 5rem !important;
  background: #f1ece6;
  background: var(--new-secondary-color);
  margin-bottom: 3rem;
  max-width: unset !important;
  font-family: "Poppins";
}
.so-form-container .card form .RFS-StepperContainer button {
  pointer-events: none !important;
}
.so-form-container .card form .RFS-StepperContainer .RFS-StepCircle {
  border-color: #ffc260 !important;
  border-color: var(--new-main-color) !important;
}
.so-form-container .card form .RFS-StepperContainer .RFS-StepCircle.active, .so-form-container .card form .RFS-StepperContainer .RFS-StepCircle.completed {
  background: linear-gradient(39deg, #ffc260 11.9%, #fd9c00 85.77%);
  border: none !important;
}
.so-form-container .card form .RFS-StepperContainer .RFS-Label {
  color: #001020 !important;
  color: var(--heading-color) !important;
  font-size: 1rem;
  font-weight: 600;
}
.so-form-container .card form .RFS-StepperContainer .RFS-Connector {
  display: none;
}
.so-form-container .card form label {
  margin-bottom: 1rem;
}
.so-form-container .card form p {
  margin: 0;
}
.so-form-container .card form .form-row {
  margin-bottom: 2.5rem;
}
.so-form-container .card form input,
.so-form-container .card form select {
  border-radius: 8px;
  border: 1px solid rgba(0, 16, 32, 0.15);
  padding: 0.75rem 1.25rem;
  height: auto;
  max-height: 50px;
  transition: all 0.3s ease-in;
  accent-color: #ffc260;
  accent-color: var(--new-main-color);
}
.so-form-container .card form input:focus-visible,
.so-form-container .card form select:focus-visible {
  outline: #ffc260;
  outline: var(--new-main-color);
  border-color: #ffc260;
  border-color: var(--new-main-color);
}
.so-form-container .card form .inline {
  display: flex;
  align-items: center;
}
.so-form-container .card form .inline input {
  border-radius: 8px 0px 0px 8px;
  border-right: 0;
}
.so-form-container .card form .inline .connector {
  background-color: rgba(0, 16, 32, 0.15);
  border: 1px solid rgba(0, 16, 32, 0.15);
  padding: 0.75rem 1rem;
  height: auto;
  max-height: 50px;
  border-radius: 0px 8px 8px 0px;
}
.so-form-container .card form .so-view-div {
  border-radius: 8px;
  border: 1px solid rgba(0, 16, 32, 0.15);
  background: #FFF;
  height: auto;
  max-height: 50px;
  padding: 0.75rem 1.25rem;
}
.so-form-container .card form .so-view-div label {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin: 0;
}
.so-form-container .card form .so-pro-select .so-view-div {
  border-color: #069399;
  border-color: var(--border-color);
}
.so-form-container .card form .div_business {
  max-width: unset;
  margin: 0;
}
.so-form-container .card form .so-category-selection {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.so-form-container .card form .so-category-selection label {
  margin-bottom: 0;
  padding: 0 10px;
}
.so-form-container .card form .so-category-selection label.active {
  background: #ffc260;
  background: var(--new-main-color);
  border-radius: 10px;
}
.so-form-container .card form .so-category-selection input {
  margin-right: 5px;
}
.so-form-container .card form .so-color-box-div .so-selected-color-div {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid;
}
.so-form-container .card form .so-color-box-div .Main {
  padding: 10px !important;
  margin-bottom: 0 !important;
  box-shadow: unset !important;
  border: unset !important;
}
.so-form-container .card form .so-color-box-div .Main button .color-box {
  border-radius: 50%;
  box-shadow: unset;
  border: 1px solid;
}
.so-form-container .card form .so-logo-box .option-box-1 {
  box-shadow: unset;
  border-radius: 8px;
  border: 1px solid rgba(6, 147, 153, 0.2);
  background: rgba(37, 229, 195, 0.1);
}
.so-form-container .card form .so-logo-box .option-box-1 a:hover {
  text-decoration: unset;
}
.so-form-container .card form .so-logo-box label {
  margin-bottom: 0;
}
.so-form-container .card form .next-button-div {
  margin-top: 3rem;
}
.so-form-container .card form .next-button-div button {
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(90deg, #ffc260 0%, #fd9c00 134.29%);
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.15);
  padding: 1rem 0;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: all 0.3s ease-in-out;
}
.so-form-container .card form .next-button-div button:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.so-form-container .card form .mini-head {
  grid-gap: 10px;
  gap: 10px;
}
.so-form-container .card form .mini-head .number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #ffc260;
  border: 1px solid var(--new-main-color);
  font-family: "Poppins";
  font-size: 1rem;
  color: #ffc260;
  color: var(--new-main-color);
}
.so-form-container .card form .chip {
  background-color: #ffc260;
  background-color: var(--new-main-color);
  order: 2;
}
.so-form-container .card form .MuiIconButton-label svg {
  fill: #069399;
  fill: var(--border-color);
}
.so-form-container .card form .subscribe a.btn {
  border-radius: 8px;
  border: 1px solid #069399;
  border: 1px solid var(--border-color);
  background: #FFF;
  padding: 0.75rem 1.25rem;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  max-width: 200px;
  justify-content: center;
  box-shadow: 0px 7.5px 20px 0px rgba(0, 0, 0, 0.1);
  font-weight: 700;
  color: #069399;
  color: var(--border-color);
  transition: all 0.3s ease-in-out;
}
@media (max-width: 500px) {
  .so-form-container .card form .subscribe a.btn {
    max-width: 100%;
  }
}
.so-form-container .card form .subscribe a.btn:hover {
  background-color: #069399;
  background-color: var(--border-color);
  color: #FFF;
  color: var(--pure-white);
}
.so-form-container .card form .subscribe a.btn:hover svg path {
  fill: #FFF;
  fill: var(--pure-white);
}
.so-form-container .card form .black-bg {
  background-color: #001020;
  background-color: var(--dark-bg);
  color: #FFF;
  color: var(--pure-white);
  padding: 10px 25px;
}
.so-form-container .card form .black-bg:hover {
  background-color: #005694;
  background-color: var(--btn-hover);
}
.so-form-container .card form .veriy-btn {
  padding: 0.725rem;
  border-radius: 8px;
  font-size: 1.125rem;
  font-weight: 700;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.15);
  background: linear-gradient(90deg, #ffc260 0%, #fd9c00 134.29%);
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.15);
  color: #FFF;
  color: var(--pure-white);
  transition: all 0.3s ease-in-out;
}
.so-form-container .card form .veriy-btn:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.so-form-container .card form .green-bg {
  background-color: #5EDD60;
  pointer-events: none;
  padding: 10px 25px;
}
.so-form-container .card form .so-verify-token input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.so-form-container .card form .so-verify-token button {
  margin: 0;
  padding: 0.85rem 0rem;
  min-width: 160px;
  border-radius: 0px 8px 8px 0px;
  font-weight: 700;
  background: #001020;
  background: var(--dark-bg);
}
.so-form-container .card form .so-verify-token button.green-bg {
  background-color: #5EDD60;
  pointer-events: none;
}
.so-form-container .card form .so-verify-token button:hover {
  background-color: #363636;
}
.so-form-container .card form .so-verify-token .brandbldr-icon {
  position: absolute;
  right: -70px;
  bottom: -15px;
}
.so-form-container .card form .searchWrapper {
  border: none;
  padding: 0;
  margin-top: -3px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}
.so-form-container .card form .searchWrapper input {
  order: 1;
  width: 100%;
  margin-bottom: 1rem;
}
.so-form-container .card form .guidline {
  color: #ff9d00;
  font-size: 0.75rem;
  font-weight: 500;
}
.so-form-container .card form .step2_formgroup .access-store-ul {
  list-style: none;
}
.so-form-container .card form .step2_formgroup .access-store-ul.first {
  margin-left: 2.3rem;
}
.so-form-container .card form .step2_formgroup .access-store-ul li {
  margin-bottom: 10px;
  text-align: left;
}
.so-form-container .card form .step2_formgroup .access-store-ul li .tick-icon {
  margin-right: 10px;
}
.so-form-container .card form .step2_formgroup .access-store-ul li .trial {
  text-decoration: underline;
  color: #069399;
  color: var(--border-color);
  font-weight: 300;
}
.so-form-container .card form .step2_formgroup .access-store-ul li a.btn {
  border-radius: 8px;
  background: linear-gradient(90deg, #149CC4 0%, #25E5C3 125.83%);
  box-shadow: 0px 7.5px 20px 0px rgba(0, 0, 0, 0.1);
  color: #FFF;
  color: var(--pure-white);
  padding: 10px 25px;
  font-family: "Inter";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  max-width: 200px;
  grid-gap: 10px;
  gap: 10px;
}
.so-form-container .card form .step2_formgroup .access-store-ul li a.btn img {
  width: 20px;
}
.so-form-container .card form .disclaimer {
  margin-top: 30px;
  font-weight: bold;
  color: #E91A1B;
  color: var(--dark-red);
}
.so-form-container .card form .footer-btns {
  margin-top: 10px;
}
.so-form-container .card form .footer-btns .buttons__button--back {
  background-color: #001020;
  background-color: var(--dark-bg);
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.15);
  font-size: 1.125rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  transition: all 0.3s ease-in-out;
}
.so-form-container .card form .footer-btns .buttons__button--back:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.so-form-container .card form .footer-btns .buttons__button--next {
  background-color: #00d700;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.15);
  padding: 1rem;
  border-radius: 8px;
  font-size: 1.125rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  transition: all 0.3s ease-in-out;
}
.so-form-container .card form .footer-btns .buttons__button--next.danger {
  background-color: #E91A1B;
  background-color: var(--dark-red);
}
.so-form-container .card form .footer-btns .buttons__button--next:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.so-form-container .card form .footer-btns .buttons__button--next:disabled {
  opacity: 0.65;
}
.so-form-container .card .congrats h5 {
  color: #001020;
  color: var(--heading-color);
  margin-top: 1.5rem;
  font-family: "Poppins";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.so-form-container .card .congrats h6 {
  color: #fd9c00 !important;
  font-size: 1rem;
}
.so-form-container .card .congrats .info p {
  font-size: 0.875rem;
}
.so-form-container .card .congrats .step2_formgroup {
  margin-top: 50px;
  padding: 2rem 1.375rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px solid rgba(0, 16, 32, 0.15);
  background: #FFF;
  background: var(--pure-white);
}
.so-form-container .card .congrats .step2_formgroup .whats_next_box h4 {
  text-align: center !important;
  font-size: 1.5rem;
  font-weight: 600;
}
.so-form-container .card .congrats .step2_formgroup .publish-store h5 {
  font-size: 1.25rem;
  margin-left: 20px;
  color: #fd9c00;
}
.so-form-container .card .congrats .step2_formgroup ul {
  margin-bottom: 0;
}
.so-form-container .card .congrats .step2_formgroup ul li {
  font-size: 0.875rem;
  list-style: none;
  background-image: url(/static/media/tick-svg.be19156c.svg);
  background-repeat: no-repeat;
  padding-left: 2rem;
}
.so-form-container .card .congrats .step2_formgroup ul li:last-child {
  margin-bottom: 0;
}
.so-form-container .card .congrats .step2_formgroup ul li a {
  font-weight: 700;
  color: #fd9c00;
}
.so-form-container .card .congrats .faq {
  margin-top: 50px;
  padding: 2rem 1.375rem;
  border-radius: 8px;
  border: 1px solid rgba(0, 16, 32, 0.15);
  background: #FFF;
  background: var(--pure-white);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.so-form-container .card .congrats .faq h4 {
  text-align: center !important;
  font-size: 1.5rem;
  font-weight: 600;
}
.so-form-container .card .congrats .faq #accordion .card-header {
  border: 1px solid #ffc260;
  border: 1px solid var(--new-main-color);
  border-radius: 8px;
  background: #f1ece6;
  background: var(--new-secondary-color);
}
.so-form-container .card .congrats .faq #accordion .card-header h5 {
  margin: 0;
}
.so-form-container .card .congrats .faq #accordion .card-header h5 button {
  text-align: left;
  width: 100%;
}
.so-form-container .card .congrats .faq #accordion .card-header h5 button:focus {
  box-shadow: unset !important;
}
.so-form-container .card .congrats .faq #accordion .card-body {
  padding: 1rem 2rem;
}
.so-form-container .card .congrats .faq #accordion .card-body li {
  font-size: 0.875rem;
  margin-bottom: 10px;
}
.so-form-container .card .congrats .faq #accordion .card-body li a {
  color: #fd9c00;
  font-weight: 700;
}

.popup-content {
  font-family: Poppins;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.popup-content .show-box,
.popup-content .step2_formgroup > ul {
  height: 90vh;
  overflow-y: scroll;
}
.popup-content h4 {
  color: #001020;
  color: var(--heading-color);
}
.popup-content h4 span {
  display: block;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  background-color: #069399;
  background-color: var(--border-color);
}
.popup-content ul::-webkit-scrollbar-thumb {
  background: #069399 !important;
  background: var(--border-color) !important;
}
.popup-content ul {
  background-color: rgba(6, 147, 153, 0.1) !important;
}

.show-box {
  padding: 1rem;
}
.show-box h4 {
  font-family: Poppins;
  font-size: 1.15rem;
  line-height: 1.8em;
  text-align: left !important;
  color: #001020;
  color: var(--dark-bg);
}
.show-box ul {
  font-family: Inter;
  list-style: inside;
  margin-bottom: 1rem;
}

@media (max-width: 1440px) {
  .so-main-container .banner.is-affiliate {
    margin-bottom: 250px;
  }
  .so-main-container .banner.is-affiliate .logo-section {
    margin-bottom: 50px;
  }
  .so-main-container .banner .divider {
    bottom: -5rem;
  }
  .so-main-container .banner .absolute {
    bottom: -10rem;
  }
}
@media (max-height: 1000px) {
  .so-main-container .banner {
    min-height: 110vh;
    padding-top: 30px;
  }
  .so-main-container .banner.is-affiliate {
    margin-bottom: 250px;
  }
  .so-main-container .banner.is-affiliate .divider {
    bottom: -5rem;
  }
  .so-main-container .banner .btn.is-affiliate {
    margin-bottom: 25rem;
  }
  .so-main-container .banner .logo-section {
    margin-bottom: 0;
  }
  .so-main-container .banner .video-section {
    margin: 30px auto;
  }
  .so-main-container .banner .absolute {
    bottom: -10rem;
  }
}
@media (max-width: 1380px) {
  .so-main-container .banner {
    min-height: 120vh;
  }
}
@media (max-width: 500px) {
  :root {
    font-size: 14px;
  }
  .so-mt-sm {
    margin-top: 1.5rem !important;
  }
  .so-main-container .banner {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 50px !important;
    min-height: 105vh;
  }
  .so-main-container .banner h1 {
    font-size: 2rem;
  }
  .so-main-container .banner p {
    font-size: 1.5rem;
  }
  .so-main-container .banner small {
    font-size: 1.25rem;
  }
  .so-main-container .banner .btn {
    padding: 1.5rem;
    width: 100%;
    margin-bottom: 3.5rem;
  }
  .so-main-container .banner .btn.is-affiliate {
    margin-bottom: 3.5rem !important;
  }
  .so-main-container .banner .divider {
    bottom: -5px;
  }
  .so-main-container .banner .absolute {
    bottom: 0px;
    transform: unset;
    left: unset;
    position: relative;
  }
  .so-main-container .banner .logo-section {
    margin: 0 auto 50px;
  }
  .so-main-container .working .card {
    margin-bottom: 2rem;
  }
  .so-main-container .about img {
    width: 100%;
    height: 400px;
    margin-bottom: 2rem;
  }
  .so-form-container .card {
    padding: 2rem 1.5rem;
  }
  .so-form-container .card .congrats .step2_formgroup {
    padding: 3rem 1rem;
  }
  .so-form-container .card .congrats .step2_formgroup ul li {
    min-height: 20px;
  }
  .so-form-container .card .div_business .text {
    width: 100%;
    margin-bottom: 1rem;
  }
  .so-form-container .card form .RFS-StepperContainer {
    padding: 1.5rem !important;
  }
  .so-form-container .card form .so-color-box-div .Main {
    width: 85%;
  }
  .so-form-container .card form .option-box-1 a,
  .so-form-container .card form .option-box-1 label {
    text-align: center;
  }
  .so-form-container .card form .so-view-div {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .so-form-container .card form .so-verify-token .brandbldr-icon {
    right: -10px;
    bottom: 45px;
  }
  .so-form-container .card form .font_selector_dropdown {
    margin-bottom: 1.5rem;
  }
  .so-form-container .card form .select-category {
    margin-top: 2rem;
  }
  .so-form-container .card form .step2_formgroup .access-store-ul.first {
    margin-left: 0;
  }
}
@media (max-width: 500px) and (max-height: 800px) {
  .so-main-container .banner {
    min-height: 120vh;
  }
}/*# sourceMappingURL=style.css.map */
:root {
  font-size: 16px;
  --heading-color: #001020;
  --primary-color: #25E5C3;
  --secondary-color: #149CC4;
  --pure-white: #FFF;
  --border-color: #069399;
  --dark-bg: #001020;
  --btn-hover: #005694;
}

.disabled {
  opacity: 0.7 !important;
  pointer-events: none !important;
  cursor: not-allowed;
}
.disabled:hover {
  transform: unset !important;
}

.filter-date-dash h4 {
  color: #001020;
  color: var(--heading-color);
  font-size: 1rem;
  width: 30%;
  margin-bottom: 0;
  text-align: left;
}
.filter-date-dash .react-datepicker__input-container input {
  width: 100%;
  border: 1px solid #069399;
  border: 1px solid var(--border-color);
  border-right: 0;
  padding: 0.5rem 1rem;
  border-radius: 4px 0 0 4px;
}
.filter-date-dash .react-datepicker__input-container input:focus-visible {
  border-color: #149CC4;
  border-color: var(--secondary-color);
  outline: unset;
}
.filter-date-dash .form-group {
  width: 30%;
}
.filter-date-dash .form-group select {
  height: 42px;
  background-color: #069399;
  background-color: var(--border-color);
  color: #fff;
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  text-align: center;
}
.filter-date-dash .form-group select:focus-visible {
  outline: unset;
  box-shadow: unset;
}

.so-modalverify-btn {
  height: 40px;
  margin-top: 30px;
}

.custom-loader {
  z-index: 9999 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.preview-imgs {
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 1rem;
}
.preview-imgs img {
  border-radius: 4px;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.email-template-card .wrapperClassName {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.email-template-card .editorClassName {
  padding: 0.375rem 0.75rem;
  min-height: 250px;
}

.dropzone-input {
  display: block !important;
  width: 0;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.not-editable {
  background-color: #D9D9D9 !important;
  pointer-events: none;
}

.swal2-styled:focus {
  outline: none !important;
  box-shadow: unset !important;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}
.wrapper #sidebar {
  position: fixed;
  min-width: 300px;
  max-width: 300px;
  min-height: 100vh;
  padding: 20px 15px;
  color: #FFF;
  color: var(--pure-white);
  transition: all 0.3s;
}
.wrapper #sidebar .sidebar-content {
  border-radius: 8px;
  background: linear-gradient(180deg, #149CC4 0%, #25E5C3 100%);
  background: linear-gradient(180deg, var(--secondary-color) 0%, var(--primary-color) 100%);
  height: 96vh;
  padding: 15px;
}
.wrapper #sidebar .sidebar-content ul li {
  margin-bottom: 10px;
}
.wrapper #sidebar .sidebar-content ul li a {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  grid-gap: 14px;
  gap: 14px;
  align-items: center;
  padding: 16px;
  color: #FFF;
  color: var(--pure-white);
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}
.wrapper #sidebar .sidebar-content ul li a:hover {
  background-color: #001020;
  background-color: var(--dark-bg);
}
.wrapper #sidebar .sidebar-content ul li.active a {
  background-color: #001020;
  background-color: var(--dark-bg);
}
.wrapper #sidebar .sidebar-header {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  margin-bottom: 15px;
  justify-content: space-between;
}
.wrapper #sidebar .sidebar-header .sidebar-logo {
  max-width: 180px;
}
.wrapper #sidebar .sidebar-header .btn-toggle {
  padding: 0;
  outline: none !important;
  box-shadow: unset !important;
}
.wrapper #sidebar.active {
  margin-left: -250px;
}
.wrapper #content {
  width: calc(100% - 300px);
  padding: 15px 30px;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}
.wrapper #content .btn-gradient {
  background: linear-gradient(90deg, #149CC4 0%, #25E5C3 134.29%);
  background: linear-gradient(90deg, var(--secondary-color) 0%, var(--primary-color) 134.29%);
  color: #FFF;
  color: var(--pure-white);
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
}
.wrapper #content .btn-gradient:hover {
  transform: scale(1.1);
}
.wrapper #content .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper #content .header h2 {
  color: #001020;
  color: var(--heading-color);
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.wrapper #content .header .btns {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
}
.wrapper #content .header .btns button {
  border-radius: 8px;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  grid-gap: 9px;
  gap: 9px;
  cursor: pointer;
}
.wrapper #content .header .btns .btn-outline {
  border: 1px solid #069399;
  border: 1px solid var(--border-color);
  color: #069399;
  color: var(--border-color);
  transition: all 0.3s ease-in-out;
}
.wrapper #content .header .btns .btn-outline:hover {
  background-color: #069399;
  background-color: var(--border-color);
  color: #FFF;
  color: var(--pure-white);
  transform: scale(1.1);
}
.wrapper #content .header .btns .btn-outline:hover svg path {
  stroke: #FFF;
  stroke: var(--pure-white);
}
.wrapper #content .body .so-hr {
  background: linear-gradient(25deg, #149CC4 -25%, #25E5C3 50.86%);
  background: linear-gradient(25deg, var(--secondary-color) -25%, var(--primary-color) 50.86%);
  opacity: 0.5;
  height: 1px;
  width: 100%;
}
.wrapper #content .body .so-dash-stats {
  color: #001020;
  color: var(--heading-color);
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.wrapper #content .body .home-card {
  border-radius: 8px;
  background: linear-gradient(85deg, #149CC4 -9.3%, #25E5C3 108.86%);
  background: linear-gradient(85deg, var(--secondary-color) -9.3%, var(--primary-color) 108.86%);
  padding: 20px 30px;
  color: #FFF;
  color: var(--pure-white);
  display: flex;
  justify-content: space-between;
  align-items: end;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.wrapper #content .body .home-card:hover {
  transform: scale(1.02);
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.15);
  background: linear-gradient(25deg, #149CC4 -25%, #25E5C3 50.86%);
  background: linear-gradient(25deg, var(--secondary-color) -25%, var(--primary-color) 50.86%);
}
.wrapper #content .body .home-card .left {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}
.wrapper #content .body .home-card .left .text {
  font-size: 18px;
  font-style: normal;
}
.wrapper #content .body .home-card .right .number {
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: 1em;
}
.wrapper #content .body .user-card {
  background: linear-gradient(265deg, #149CC4 -9.3%, #25E5C3 108.86%);
  background: linear-gradient(265deg, var(--secondary-color) -9.3%, var(--primary-color) 108.86%);
}
.wrapper #content .body .table {
  width: 100% !important;
}
.wrapper #content .body .table .rdt_Pagination {
  border: none;
}
.wrapper #content .body .table .text-end {
  margin: 20px auto;
  display: flex;
  justify-content: end;
}
.wrapper #content .body .table .text-end input {
  border-radius: 8px;
  border: 1px solid rgba(6, 147, 153, 0.2);
  padding: 13px 20px 13px 45px;
  background: #FFF;
  background: var(--pure-white);
  width: 30%;
  font-size: 16px;
  font-style: normal;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGKSURBVHgBpVO7bsJAEFzbdFCko7WAnnwCSLy6ODVQpKNLQw/5khT8AOkQQoI0tIEPgJAK6FLYnR+ZQXEE8Tk4MJJ1e3fr2Zt9aPILlUqlpOv6ne/7JrY3sBewXyaTyUz+gBYajUbDdF33GWYpk8lINpsVrqvVSmzbFk3TFoZh3I9Go00sEUk8z5um02mz3W5LvV4X2D9O4/FYBoOB7Pf7DcjKcWSU89ZqtYLdbhfEgXeWZQXwnao4dOYE6y1fQjlx4B19KP37n1MiBLOYi1qtJudAH/oiX1aECIfFfD4vSRAWAcGLESJ8n6xKUpBMBUr7QDUkKRnbASqWESIcDkkyn8/PkrAN6IvgQ6UDS8rSbrfb2PLzrtlsBtVq9V3FwRxJKpV6cBxn0+12D1FVL+l0OmxIylK+5mRE2N0IbrKrC4XCQQYaURCELjPcLUH0CLuP2XtSEh3JLLFPwhIzscxJOLS472Ppqcj+DZLh47j0wjNDLsB6vZ7lcjmq6WPl/lWTK3AksyzXIhzgL87R8Ij789ftAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 15px;
  transition: all 0.3s ease-in-out;
}
.wrapper #content .body .table .text-end input:focus {
  border-color: #069399;
  border-color: var(--border-color);
  outline: unset;
}
.wrapper #content .body .table .rdt_TableHead {
  border-radius: 8px 8px 0px 0px;
  color: #FFF;
  color: var(--pure-white);
  overflow: hidden;
  font-size: 16px;
}
.wrapper #content .body .table .rdt_TableHead .rdt_TableHeadRow {
  background-color: #001020;
  background-color: var(--dark-bg);
  padding: 15px 30px;
}
.wrapper #content .body .table span.price {
  font-weight: 500;
  color: #069399;
  color: var(--border-color);
}
.wrapper #content .body .table .rdt_TableBody .rdt_TableRow {
  border-bottom: 1px solid rgba(6, 147, 153, 0.2);
  border-left: 1px solid rgba(6, 147, 153, 0.2);
  border-right: 1px solid rgba(6, 147, 153, 0.2);
  padding: 14px 30px;
  font-size: 16px;
}
.wrapper #content .body .table .rdt_TableBody .rdt_TableRow:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.wrapper #content .body .table .action-btns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  grid-gap: 10px;
  gap: 10px;
}
.wrapper #content .body .table .action-btns button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 38px;
  box-shadow: unset !important;
}
.wrapper #content .body .table .action-btns .btn-edit {
  border-radius: 8px;
  border: 1px solid #069399;
  border: 1px solid var(--border-color);
}
.wrapper #content .body .table .action-btns .btn-edit:hover {
  background-color: #069399;
  background-color: var(--border-color);
  cursor: pointer;
}
.wrapper #content .body .table .action-btns .btn-edit:hover svg path {
  stroke: #FFF;
  stroke: var(--pure-white);
}
.wrapper #content .body .table .action-btns .btn-view {
  border-radius: 8px;
  border: 1px solid #001020;
  border: 1px solid var(--heading-color);
}
.wrapper #content .body .table .action-btns .btn-view:hover {
  background-color: #001020;
  background-color: var(--heading-color);
  cursor: pointer;
}
.wrapper #content .body .table .action-btns .btn-view:hover svg path {
  stroke: #FFF;
  stroke: var(--pure-white);
}
.wrapper #content .body .table .action-btns .btn-password {
  border-radius: 8px;
  border: 1px solid #005694;
}
.wrapper #content .body .table .action-btns .btn-password:hover {
  background-color: #005694;
  cursor: pointer;
}
.wrapper #content .body .table .action-btns .btn-password:hover svg {
  fill: #FFF;
  fill: var(--pure-white);
}
.wrapper #content .body .table .action-btns .btn-password:hover svg path {
  stroke: #FFF;
  stroke: var(--pure-white);
}
.wrapper #content .body .table .action-btns .btn-delete {
  border-radius: 8px;
  border: 1px solid #D30C0C;
}
.wrapper #content .body .table .action-btns .btn-delete:hover {
  background-color: #D30C0C;
  cursor: pointer;
}
.wrapper #content .body .table .action-btns .btn-delete:hover svg path {
  stroke: #FFF;
  stroke: var(--pure-white);
}
.wrapper #navbar {
  border-bottom: 1px solid rgba(6, 147, 153, 0.2);
  margin-bottom: 20px;
}
.wrapper #navbar .user {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  grid-gap: 10px;
  gap: 10px;
}
.wrapper #navbar .user .img {
  width: 36px;
}
.wrapper #navbar .user .dropdown-menu {
  left: -55px;
  top: 155%;
}
.wrapper .modal .close {
  position: absolute;
  right: 20px;
  top: 20px;
  background: #069399;
  background: var(--border-color);
  color: #FFF;
  color: var(--pure-white);
  height: 30px;
  width: 30px;
  border-radius: 300px !important;
  padding: 0 !important;
  opacity: 1;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-filter: drop-shadow(0px 10px 15px rgba(6, 147, 153, 0.25));
          filter: drop-shadow(0px 10px 15px rgba(6, 147, 153, 0.25));
}
.wrapper .modal .modal-body {
  padding: 48px 55px;
}
.wrapper .modal .modal-body h2 {
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-bottom: 35px;
}
.wrapper .modal .modal-body h4 {
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-bottom: 10px;
  color: #069399;
  color: var(--border-color);
}
.wrapper .modal .modal-body small {
  margin-top: -10px;
  display: block;
  margin-bottom: 18px;
}
.wrapper .modal .modal-body .separator:not(:last-child) {
  border-bottom: 1px dashed rgba(0, 16, 32, 0.35);
  margin-bottom: 25px;
  margin-top: 12px;
}
.wrapper .modal .modal-body .big-one {
  border-radius: 8px;
  border: 1px solid rgba(6, 147, 153, 0.2);
  padding: 2rem 3rem;
  background: rgba(37, 229, 195, 0.1);
  margin-bottom: 2rem;
}
.wrapper .modal .modal-body .form-group {
  text-align: left;
}
.wrapper .modal .modal-body .form-group label {
  color: #001020;
  color: var(--heading-color);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.wrapper .modal .modal-body .form-group input,
.wrapper .modal .modal-body .form-group select,
.wrapper .modal .modal-body .form-group textarea {
  border-radius: 8px;
  border: 1px solid rgba(0, 16, 32, 0.15);
  padding: 10px 15px;
  background: #FFF;
  background: var(--pure-white);
  margin-bottom: 18px;
  margin-top: 5px;
  height: auto;
  font-family: Inter;
}
.wrapper .modal .modal-body .form-group input:disabled,
.wrapper .modal .modal-body .form-group select:disabled,
.wrapper .modal .modal-body .form-group textarea:disabled {
  background-color: #f2f2f2;
}
.wrapper .modal .modal-body .form-group .group {
  margin-bottom: 18px;
}
.wrapper .modal .modal-body .form-group .group .input-group {
  margin: 0;
}
.wrapper .modal .modal-body .form-group .group .input-group .input-group-text {
  border-radius: 8px;
  padding: 10px 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
.wrapper .modal .modal-body .form-group .group input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin: 0;
  box-shadow: unset;
}
.wrapper .modal .modal-body .form-group .right-group {
  margin-bottom: 18px;
}
.wrapper .modal .modal-body .form-group .right-group .input-group {
  width: auto;
  margin: 0;
}
.wrapper .modal .modal-body .form-group .right-group .input-group .input-group-text {
  border-radius: 8px;
  padding: 10px 15px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}
.wrapper .modal .modal-body .form-group .right-group .input-group.active .input-group-text {
  background-color: #25E5C3;
}
.wrapper .modal .modal-body .form-group .right-group .input-group.active .input-group-text svg {
  fill: #fff;
}
.wrapper .modal .modal-body .form-group .right-group input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin: 0;
  box-shadow: unset;
}
.wrapper .modal .modal-body .affiliate-group input {
  margin: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.wrapper .modal .modal-body .affiliate-group .input-group-text {
  border-left: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: all 0.3s ease-in-out;
  background-color: #069399;
  background-color: var(--border-color);
}
.wrapper .modal .modal-body .affiliate-group .input-group-text:hover {
  background-color: #149CC4;
  background-color: var(--secondary-color);
}
.wrapper .modal .modal-body button {
  font-weight: 500;
  width: 100%;
  padding: 12px 24px;
  border-radius: 8px;
}
.wrapper .so-modal .modal-dialog {
  max-width: 85%;
}
.wrapper .so-modal .modal-dialog .form-group {
  max-width: unset;
}
.wrapper .so-modal .modal-dialog .btn {
  max-width: 320px;
  margin: 0 auto;
}
.wrapper.collapsed #sidebar {
  min-width: 115px;
  max-width: 115px;
}
.wrapper.collapsed #sidebar .sidebar-header .sidebar-logo {
  display: none !important;
}
.wrapper.collapsed #sidebar .sidebar-header .btn-toggle {
  width: 100%;
}
.wrapper.collapsed #sidebar .sidebar-content ul li a .text {
  display: none !important;
}
.wrapper.collapsed #content {
  width: calc(100% - 115px);
}

#login {
  background: url(/static/media/login-bg.ec4e1133.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
}
#login .login-card {
  border-radius: 8px;
  background: #FFF;
  background: var(--pure-white);
  box-shadow: 0px 10px 60px 0px rgba(6, 147, 153, 0.2);
  border: none;
  min-width: 30%;
  padding: 2.6% 2.6% 2.34%;
}
#login .login-card .logo {
  text-align: center;
  max-width: 30%;
  margin: 0 auto;
  margin-bottom: 35px;
}
#login .login-card .headings {
  color: #001020;
  color: var(--heading-color);
  text-align: center;
}
#login .login-card .headings h1 {
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
#login .login-card .headings h3 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
#login .login-card .card-body .form-group {
  text-align: left;
  max-width: 100% !important;
}
#login .login-card .card-body .form-group label {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
#login .login-card .card-body .form-group .form-control {
  border-radius: 8px;
  border: 1px solid rgba(0, 16, 32, 0.15);
  background: #FFF;
  background: var(--pure-white);
  padding: 10px 15px;
  margin-bottom: 25px;
  height: auto;
}
#login .login-card .card-body .info {
  margin-top: -5px;
}
#login .login-card .card-body .info label {
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  color: #069399;
  color: var(--border-color);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
#login .login-card .card-body .info label input {
  accent-color: #069399;
  accent-color: var(--border-color);
}
#login .login-card .card-body .info .forgot-password a {
  color: #069399;
  color: var(--border-color);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
#login .login-card .card-body .btn-gradient {
  border-radius: 8px;
  background: linear-gradient(90deg, #149CC4 0%, #25E5C3 134.29%);
  background: linear-gradient(90deg, var(--secondary-color) 0%, var(--primary-color) 134.29%);
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.15);
  padding: 15px;
  width: 100%;
  color: #FFF;
  color: var(--pure-white);
  margin-top: 30px;
  margin-bottom: 35px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}
#login .login-card .card-body .btn-gradient:hover {
  transform: scale(1.1);
}
#login .login-card .card-body .footer {
  color: #444;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
#login .login-card .card-body .footer a {
  color: #069399;
  color: var(--border-color);
}

.profile .form-group {
  text-align: left;
  max-width: 100%;
  margin-bottom: 1rem;
}

.account-management-modal .RFS-StepperContainer {
  border-radius: 8px;
  border: 1px solid rgba(6, 147, 153, 0.2);
  padding: 1.5rem 5rem !important;
  background: rgba(37, 229, 195, 0.1);
  margin-bottom: 3rem;
  max-width: unset !important;
  font-family: "Poppins";
}
.account-management-modal .RFS-StepperContainer .RFS-StepCircle-d4,
.account-management-modal .RFS-StepperContainer .RFS-StepCircle {
  width: 3em !important;
  height: 3em;
  display: flex;
  font-size: 1em;
  align-items: center;
  border-radius: 50% !important;
  justify-content: center;
}
.account-management-modal .RFS-StepperContainer .RFS-StepCircle {
  border-color: #069399 !important;
  border-color: var(--border-color) !important;
}
.account-management-modal .RFS-StepperContainer .RFS-StepCircle.active, .account-management-modal .RFS-StepperContainer .RFS-StepCircle.completed {
  background: linear-gradient(39deg, #0B4F9D 11.9%, #25E5C3 85.77%);
  border: none !important;
}
.account-management-modal .RFS-StepperContainer .RFS-Label {
  color: #001020 !important;
  color: var(--heading-color) !important;
  font-size: 1rem;
  font-weight: 600;
}
.account-management-modal .RFS-StepperContainer .RFS-Connector {
  display: none;
}
.account-management-modal .so-color-box-div {
  margin-bottom: 20px;
}
.account-management-modal .so-color-box-div .so-selected-color-div {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  border: 1px solid;
}
.account-management-modal .Main {
  box-shadow: unset;
  border: none;
  margin-bottom: 0;
  padding: 12px;
}
.account-management-modal .Main button {
  width: auto !important;
  padding: 0 !important;
}
.account-management-modal .Main button .color-box {
  border-radius: 50% !important;
  width: 25px;
  height: 25px;
}
.account-management-modal .div_business .inline {
  display: flex;
  align-items: center;
}
.account-management-modal .div_business .inline input {
  border-radius: 3px 0px 0px 3px;
  border-right: 0;
}
.account-management-modal .div_business .inline .connector {
  background-color: rgba(0, 16, 32, 0.15);
  border: 1px solid rgba(0, 16, 32, 0.15);
  padding: 6px 10px 0px;
  height: 40px;
  max-height: 50px;
  border-radius: 0px 3px 3px 0px;
}
.account-management-modal .footer-btns .buttons__button--back {
  background-color: #001020;
  background-color: var(--dark-bg);
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.15);
  font-size: 1.125rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
  transition: all 0.3s ease-in-out;
}
.account-management-modal .footer-btns .buttons__button--back:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.account-management-modal .footer-btns .buttons__button--next {
  background: linear-gradient(90deg, #149CC4 0%, #25E5C3 134.29%);
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.15);
  padding: 1rem;
  border-radius: 8px;
  font-size: 1.125rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  transition: all 0.3s ease-in-out;
}
.account-management-modal .footer-btns .buttons__button--next:hover {
  transform: scale(1.05);
  cursor: pointer;
}

@media (max-width: 768px) {
  .wrapper #sidebar.active {
    margin-left: 0;
  }
  .wrapper.mobile #sidebar {
    display: none;
    width: 0;
    position: absolute;
    left: 0;
    z-index: 999;
    height: 100vh;
  }
  .wrapper.mobile #navbar {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
  }
  .wrapper.mobile #navbar .btn-toggle {
    padding: 0;
  }
  .wrapper.mobile #content {
    width: 100%;
  }
  .wrapper #content .header {
    flex-wrap: wrap;
  }
  .wrapper #content .header h2 {
    font-size: 24px;
  }
  .wrapper #content .header .btns {
    flex-wrap: wrap;
  }
  .wrapper #content .header .btns button {
    width: 100%;
  }
  .wrapper #content .body .home-card {
    margin-bottom: 15px;
  }
  .wrapper #content .body .table .text-end input {
    width: 100%;
  }
  .wrapper .so-modal .modal-dialog {
    max-width: unset;
  }
  .wrapper .modal .close {
    width: 30px !important;
  }
  .wrapper .modal .modal-body {
    padding: 40px 20px;
  }
  #login .login-card {
    min-width: 85%;
  }
}
@media (max-width: 600px) {
  .multiSelectContainer .optionListContainer {
    top: -17rem;
  }
}/*# sourceMappingURL=dashboard.css.map */
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Fira Sans', sans-serif !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.color-box {
  height: 30px;
  width: 30px;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 0px 10px #0000003b;
}

.blue {
  background-color: #31d2ff !important;
}

.green {
  background-color: #8cff6f;
}

.orange {
  background-color: #fda437;
}

.Main {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: start;
  margin-bottom: 30px;
  background: white;
  box-shadow: 0px 0px 10px #dddddd49;
  padding: 20px;
  border-top: 1px solid #dddddd7d;
  flex-wrap: wrap;
}

.DivColor {
  display: flex;
  justify-content: center !important;
  margin-bottom: 20px;
}

.Main button {
  border: none;
  background-color: transparent;
}

.form-group__element.font_selector_dropdown {
  max-width: 850px;
  margin: 0 auto;
}

/* new-css */

h1.main-container__header {
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 5px;
  color: #084e93;
  text-transform: uppercase;
}

.header_title_div {
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  flex-direction: column;
}

p.main-container__subheader {
  font-size: 16px;
  font-weight: 400;
  color: #084e93;
}

.container_card {
  border: 1px solid #cccccc70;
  max-width: 900px;
  margin: 0 auto;
  background: white;
  padding: 20px 60px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #dddddd3b;
  margin-top: 10px;
}

.form-group {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.custome_color_picker input[type="color"] {
  width: 100%;
  height: 130px;
  border: none;
  border-radius: 25px;
  /* overflow: hidden; */
  background: none;
  border-radius: 10px 10px 0px 0px !important;
  transform: scale(1.1);
  cursor: pointer;
}

.custome_color_picker {
  overflow: hidden;
  width: 100%;
  height: 125px;
  border-radius: 15px 15px 0px 0px;
  box-shadow: 0px 0px 10px #dddddd8c;
}

button.buttons__button.buttons__button--next.btn_next {
  display: block;
  margin-left: auto;
}

.Add_file_div input {
  display: none;
}

.form-group__input {
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #ddd;
  background-color: transparent;
  height: 40px;
}

.Add_file_div label.form-group__label {
  font-size: 14px;
  color: #084e93;
  text-align: center;
  background: rgb(198 220 255 / 33%);
  display: inline-block !important;
  max-width: 90px;
  border-radius: 5px;
  height: 30px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
  cursor: pointer;
}

.Add_file_div {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px dashed #084e93;
  max-width: 850px;
  background: rgb(43 124 255 / 4%);
  margin: 0 auto 20px;
  border-radius: 5px;
}

.form-group.Main_group_log {
  max-width: 700px;
  width: 100% !important;
}

label.form-group__label span.material-symbols-outlined {
  font-size: 16px;
}

h4 {
  text-align: center;
  font-size: 22px;
  line-height: 40px;
  color: #084e93;
  margin-bottom: 20px;
  font-weight: bold;
}

h5 {
  text-align: center;
  font-size: 22px;
  line-height: 40px;
  color: #084e93;
  margin-bottom: 20px;
  font-weight: bold;
}

.StepperContainer-0-2-1 {
  margin-bottom: 30px;
}

.form-group__element.Pick_logo_div {
  max-width: 100%;
  margin: 0 auto;
  background: white;
  padding: 20px 20px;
  border-radius: 2px;
  /* box-shadow: 0px 0px 10px #dddddd3b; */
  margin-top: 20px;
}

.container_card p {
  font-size: 16px;
  color: #222;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 6px;
  text-align: start;
}

.container_card ul {
  padding: 0;
  text-align: start;
  margin: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
}

.container_card ul li {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #222;
  line-height: 22px;
}

.container_card ul li span.material-symbols-outlined {
  font-size: 18px;
}

a.btn.btn-general {
  font-size: 16px;
  background: #084e93;
  display: block;
  padding: 10px 20px;
  text-align: start;
  max-width: 185px;
  display: flex;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
  text-decoration: none;
  color: white;
  align-items: center;
  border-radius: 5px;
  margin: 20px auto;
  cursor: pointer;
}

a.btn.btn-general img {
  width: 25px;
  height: auto;
  object-fit: contain;
}

.button_div {
  max-width: 900px;
  margin-left: auto;
  display: block;
  margin: 0px auto;
  padding-bottom: 30px;
}

.div_business {
  max-width: 850px;
  width: 100%;
  margin: 20px auto;
}

.color_choose .form-group__element {
  margin: 10px auto;
  grid-gap: 20px;
  gap: 20px;
  max-width: 50%;
}

.color_choose {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
}

.Payment_Method {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.payment_input_div {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  height: 135px;
  width: 100%;
  box-shadow: 0px 0px 10px #80808057;
  justify-content: center;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  flex-direction: column;
}

.payment_input_div label.form-group__label {
  margin: 0;
}

.payment_input_div div {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.payment_input_div img {
  width: 45px;
}

.container_card.Store_Details .div_business {
  margin-bottom: 20px;
}

.div_business.Product_Display input[type="radio"] {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 5px;
}

.payment_input_div input[type="radio"] {
  height: 25px;
  width: 25px;
  margin: 0;
}

.container_card.Successfully img {
  width: 130px;
  text-align: center;
  margin: 0 auto;
  display: block;
}

.container_card.Successfully label.form-group__label {
  text-align: center;
  font-size: 20px;
}

.custom-loader {
  display: flex;
  position: fixed;
  top: 0;
  background: #000000a8;
  width: 100%;
  height: 100vh;
  right: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.View_div img {
  width: 20px;
}

.pro_select {
  background: #dddddd !important;
}

.View_div {
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 200px;
  height: 100px;
  background: white;
  box-shadow: 0px 0px 10px #ddd;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  grid-gap: 10px;
  gap: 10px;
}

.main_view_card_div {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  justify-content: flex-start;
  margin-top: 12px;
}

.div_business.Product_Display label {
  text-align: center;
}

.div_business.Product_Display input[type="radio"] {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 5px;
  position: absolute;
  width: 200px;
  height: 200px;
  opacity: 0;
}

.guidline {
  cursor: pointer;
  color: #4064bd;
  font-size: 14px;
}

div.guidline-popup-content ul li {
  list-style: none;
  display: flex;
  align-items: start;
}

li.allow_li {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
}

li.allow_li div {
  display: flex;
}

ul.Scope_ul {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  padding: 14px !important;
  padding-left: 35px !important;
}

div.guidline-popup-content ul {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  grid-gap: 20px;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px 20px;
  overflow: auto;
  max-height: 750px;
  background-color: #4064bd12;
  max-width: 95%;
  margin: 0px 20px 20px;
}


/* width */
div.guidline-popup-content ul::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
div.guidline-popup-content ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.356);

}

/* Handle */
div.guidline-popup-content ul::-webkit-scrollbar-thumb {
  background: #084e93;
}









ul.Scope_ul {
  background-color: transparent !important;
}

ul.Scope_ul li {
  font-size: 14px;
  color: #202223;
  list-style-type: disc !important;
  display: list-item !important;
}

.popup-content {
  padding: 0px !important;
  border-radius: 5px;
  width: 820px;
}

.guidline-popup-content h4 {
  max-width: 100%;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin: 0 auto;
  text-transform: capitalize;
  display: flex;
  grid-gap: 50px;
  gap: 50px;
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}

.popup_title {
  padding: 20px;
}

h4 span {
  background: rgb(0, 58, 107);
  border-radius: 50px;
  padding: 2px;
  color: white;
  font-size: 20px !important;
  cursor: pointer;
}

ul.Scope_ul ul {
  background: transparent !important;
  padding: 0px;
  margin: 0px auto;
}

div.guidline-popup-content ul li span {
  font-size: 22px;
}

ul.Scope_ul span {
  font-size: 20px !important;
  display: none;
}

.brandbldr-icon {
  width: 40px;
  height: 40px;
  margin: 20px;
}

.verify_token {
  display: flex;
}

.notesBox {
  color: #dd4c4c;
  font-size: 14px;
  font-style: italic;
}

.skip-title {
  padding: 10px;
}

.Pick_logo_div {
  padding-inline: 0 !important;
}

p.pick_logo_text {
  margin-bottom: 10px;
  font-weight: 400;
  color: #222;
  font-size: 16px;
}

.colorBoxDiv {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.colorBoxDiv input[type="color"] {
  background: #fff;
  padding: 0 3px;
  border-radius: 3px;
  box-shadow: 0px 0px 10px #dddddd49;
  height: 70px;
  width: 70px;
}

.step2_formgroup h4 {
  text-align: left;
}

.step2_formgroup {
  margin-left: 0;
  max-width: 100%;
}

.step2_formgroup a.btn.btn-general {
  margin: 0px 5px 5px;
}

a {
  text-decoration: none;
  color: #4064bd;
}


.step-box-custom {
  max-width: 100%;
  padding: 30px 0px;
  height: auto;
  cursor: pointer;
}

.title-info {
  display: flex;
  flex-direction: column;
}


.option-box-1 {
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 200px;
  height: 100px;
  background: white;
  box-shadow: 0px 0px 10px #ddd;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
}


input#storeLogo {
  display: none;
}

.main-box {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.option-box-1 label {
  font-size: 14px;
  color: #202223;
  padding: 5px 10px;
  border-radius: 5px;
}

.option-box-1 a {
  font-size: 14px;
  color: #202223;
}


.option-box-3.input-checkbox {
  padding: 20px 0px;
  text-align: end;
}

.option-box-1 img {
  width: 25px;
}

.option-box-1 label {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.option-box-1 a {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  height: 100%;
  justify-content: center;

}


.input-checkbox .d-flex {
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  grid-gap: 5px;
  gap: 5px;
  width: 100%;
  display: flex;
}

label.skip-title {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

label.skip-title input.form-group {
  width: 14px;
  height: 14px;
  max-width: 20px;
  margin: 0;
}

.skip-title input[type="checkbox"] {
  display: none;
}


div.guidline-popup-content ul.Scope_ul {
  margin: 0;
  padding: 0 0 0 60px !important;
  max-width: 100%;
  width: 100%;
}

div.guidline-popup-content ul.Scope_ul li.innerList-box {
  list-style: none !important;
  position: relative;
}

div.guidline-popup-content ul.Scope_ul li.innerList-box::before {
  content: "";
  width: 6px;
  height: 6px;
  background-color: #202223;
  position: absolute;
  left: -13px;
  top: 5px;
}

div.guidline-popup-content ul.Scope_ul li.innerList-box ul {
  padding: 5px 0 0 20px;
  margin: 0;
}

div.guidline-popup-content ul.Scope_ul li.innerList-box ul li.innerList-box::before {
  border-radius: 50%;
  top: 8px;
}


.step-box-custom-1 {
  max-width: 100%;
  padding: 30px 0px;
  height: auto;
  width: auto !important;
}

.container_card_1 ul {
  padding: 15px !important;
}

.container_card.Successfully label.form-group__label2 {
  font-size: 18px !important;
}

.container_card.Successfully label.form-group__label3 {
  font-size: 17px !important;
  padding: 0px 100px 40px;
}

.whats_next_box {
  padding: 0px 15px;
}

.whats_next_box h4 {
  margin-bottom: 0px;
}


.next-li {
  position: relative;
  padding-left: 20px;
  /* Adjust the padding as needed */
  margin-bottom: 10px;
  /* Adjust the margin as needed */
}


.custom-text {
  padding: 0px 25px;
}

.MuiIconButton-root {
  padding: 5px !important;
}

.step2_formgroup li b {
  margin-right: 10px;
}

li.next-li {
  list-style: disc;
  display: list-item !important;
  padding: 0;
  margin-left: 20px;
}

.container_card.container_card_1.Successfully p {
  text-align: center;
  max-width: 85%;
  margin: 0 auto;
  font-size: 14px;
}

.form-group__element h4 {
  font-size: 32px;
  margin-bottom: 16px;
}

.container_card.container_card_1.Successfully {
  display: flex;
  align-items: center;
  max-width: 1140px;
  padding: 0;
}


.container_card.container_card_1.Successfully .form-group__element {
  background: #0049ff14;
  margin: 0;
  padding: 50px 20px;
  width: 100%;
  max-width: 50%;
}


.btn-section a.btn.btn-general {
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  margin: 30px auto;
  padding: 15px 35px;
}


.logo-section {
  text-align: center;
}

.main-store-section {
  padding-top: 20vh;
}

.header_title_div img {
  width: 105px;
  padding: 10px;
}

.main_welcome_screen {
  width: 100%;
  margin: 0 auto;
  background-color: #e9f8fe71;
  min-height: 100vh;
  background-image: url(/static/media/banner.442bcdbd.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.logo-section img {
  width: 400px;
}

.RFS-StepperContainer {
  max-width: 900px;
  margin: 0 auto;
}

.div_business a.btn.btn-general {

  margin: 10px 0px;
}

.container_card h4 {
  text-align: center;
  font-size: 24px;
  line-height: 40px;
  color: #084e93;
  margin-bottom: 20px;
  font-weight: 500;

}



.searchWrapper {
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #ddd;
  background-color: transparent;
  height: auto;
}

.step2_formgroup.subscribe-box h4 {
  display: flex;
  justify-content: end;
}


.div_subscribe a.btn.btn-general {
  margin-left: auto !important;
  text-align: end;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 20px !important;
  margin-right: 0 !important;
  cursor: pointer;
}

.step2_formgroup.subscribe-box {
  padding: 20px 20px 30px;
}



.MuiSvgIcon-root {
  width: 27px !important;
  height: 27px !important;
  margin-left: 5px;
  font-size: 14px !important;

}

button.MuiButtonBase-root.MuiIconButton-root {
  padding: 0 !important;
}


/* .div_business.sub-plan-card {
  display: flex;
  align-items: center;
  padding: 20px;
  background: #084e930a;
  border-radius: 10px;
  border: 2px solid #084e936e;
} */

.step2_formgroup1 {
  align-items: center;
  padding: 20px;
  background: #084e930a;
  border-radius: 10px;
  border: 2px solid #084e936e;
}

/* Sohaib CSS  */
